<template>
  <UContainer :ui="{ constrained: 'max-w-xl' }" class="pt-36">
    <UCard :ui="{ ring: 'ring-0', shadow: 'shadow-none', divide: 'divide-none' }">
      <template #header>
        <div class="flex justify-between">
          <NuxtLink :to="'/'" class="text-2xl font-bold tracking-tight">
            Nomad<span class="text-gray-400">Rx</span>
          </NuxtLink>
          <GlobalLocaleDropdownUpdateButton />
        </div>
      </template>
      <AuthEmailLogin />
    </UCard>
  </UContainer>
</template>

<script setup>
definePageMeta({
  layout: "auth",
  middleware: [],
});

useSeoMeta({
  title: 'NomadRx Login | Sign in to the NomadRx Account',
  ogTitle: 'NomadRx Login | Sign in to the NomadRx Account',
  description: 'Sign in to your NomadRx Account to manage your health insurance claims and payments. Track claims, pay copays, find providers, and more.',
  ogDescription: 'Sign in to your NomadRx Account to manage your health insurance claims and payments. Track claims, pay copays, find providers, and more.',
  // ogImage: 'https://example.com/image.png',
  // twitterCard: 'summary_large_image',
})

</script>