<template>
    <div v-if="authLinkSent">
        <div class="sm:mx-auto pb-6 text-left">
            <h2 class="text-2xl tracking-tight font-bold text-gray-900" v-text="t('successTitle')" />
            <i18n-t keypath="successDescription" tag="p"
                class="mt-2 leading-6 text-gray-500 font-normal text-sm leading-snug">
                <template #email>
                    <span v-if="state.email" class="font-bold">{{ state.email }}</span>
                    <span v-else>your email</span>
                </template>
            </i18n-t>
        </div>
        <div>
            <UButton to="/" :label="t('doneButtonLabel')" />
        </div>

    </div>
    <div v-else>

        <div class="sm:mx-auto pb-6">
            <h1 class="text-2xl tracking-tight font-bold text-gray-900" v-text="t('header')" />
            <p class="mt-2 leading-6 text-gray-500" v-text="t('description')" />
        </div>

        <UForm :schema="schema" :state="state" class="space-y-4" @submit="onSubmit" :validate-on="['submit', 'change']">
            <UFormGroup name="email">
                <UInput :placeholder="t('emailPlaceholder')" size="lg" v-model=state.email autofocus="true"
                    type="email" />
            </UFormGroup>
            <UButton type="submit" block size="xl" :label="t('continueButtonLabel')" :loading="isLoading" />
        </UForm>
        <p class="text-sm mt-6 text-center">
            {{ t('createAccountCTA') }}
            <UButton to="/onboard" class="font-semibold" variant="link" :label="t('createAccountButtonLabel')" />
        </p>
    </div>
</template>

<script setup lang="ts">

import { object, string, type InferType } from 'yup'
import type { FormSubmitEvent } from '#ui/types'

const route = useRoute();

const { t } = useI18n({
    useScope: 'local'
})

let isLoading = ref(false);
let loginError = ref(false);
let authLinkSent = ref(false);

const schema = object({
    email: string().email('Invalid email').required(t('emailErrrorMessage')),
})

type Schema = InferType<typeof schema>

const state = reactive({
    email: route.query.email || '',
})

async function onSubmit(event: FormSubmitEvent<Schema>) {
    // Do something with event.data
    console.log(event.data)

    isLoading.value = true;

    const payload = {
        email: state.email,
        origin: location.origin
    }
    console.log('payload: ', payload)

    try {

        const { data } = await useFetch(`/api/auth/email-link`, {
            method: "post",
            body: payload,
            initialCache: false,
        });

        const cookie = useCookie("auth_email", { maxAge: 1000 * 60 });
        cookie.value = state.email;
        authLinkSent.value = true

    } catch (error) {

        if (error.code == "auth/wrong-password") {
            loginError.value = "Incorrect email or password.";
        } else if (error.code == "auth/user-not-found") {
            loginError.value = "Incorrect email or password.";
        } else if (error.code == "auth/too-many-requests") {
            loginError.value = "Too many requests. Try again later.";
        } else {
            loginError.value = "Login error. Please try again.";
        }
    } finally {
        isLoading.value = false;
    }
}
</script>

<i18n lang="json">{
    "en": {
        "header": "Sign in to your account",
        "description": "Enter the email address associated with your account, and we’ll send a login link to your inbox.",
        "emailLabel": "Your email",
        "emailPlaceholder": "Your email",
        "emailErrrorMessage": "Please enter your email",
        "continueButtonLabel": "Continue",
        "createAccountCTA": "New to NomadRx?",
        "createAccountButtonLabel": "Sign up",
        "successTitle": "Check your inbox.",
        "successDescription": "Click the link we sent to {email} to sign in. Hang tight. This may take 1-2 minutes.",
        "doneButtonLabel": "Done"
    },
    "es": {
        "header": "Ingresa a tu cuenta",
        "description": "Ingresa tu correo electrónico asociada con su cuenta y le enviaremos un Magic Link a tu correo.",
        "emailLabel": "Correo electrónico",
        "emailPlaceholder": "Correo electrónico",
        "emailErrrorMessage": "Por favor ingresa tu correo electrónico",
        "continueButtonLabel": "Ingresar",
        "createAccountCTA": "¿Aun no tienes una cuenta?",
        "createAccountButtonLabel": "Regístrate",
        "successTitle": "Revisa tu correo electrónico.",
        "successDescription": "Haga clic en el enlace que le enviamos a {email}.\nSe puede tardar unos 1-2 minutos.",
        "doneButtonLabel": "Hecho"
    }
}</i18n>
